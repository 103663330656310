<template>
	<button
		:disabled="disabled"
		class="roundup-button-m"
		:type="nativeType || 'button'"
		:class="[color, type, { loading: loading }]"
		@click="$emit('click', this)"
	>
		<slot />
	</button>
</template>

<script>
export default {
	name: 'roundup-button-m',
	props: ['color', 'nativeType', 'loading', 'type', 'disabled']
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.roundup-button-m {
	width: auto;
	background: #184fd5;
	color: #fff !important;
	border-radius: 25px;
	cursor: pointer;
	transition: transform 0.25s;
	display: inline-block;
	font-size: 14px;
	outline: none;
	font-weight: 600;
	padding: 10px 20px;
	margin-top: 20px;
	position: relative;
	text-decoration: none;
	border: 1px solid transparent;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		border-radius: 25px;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}
	&:hover {
		&:enabled {
			transform: translateY(-2px);
			&::after {
				opacity: 1;
			}
		}
	}
	&.primary {
		background: $roundup-primary;
		color: #fff;
	}
	&.secondary {
		background: $roundup-light-grey;
		color: #000 !important;
		&:hover {
			background: #92979e;
			color: #fff !important;
		}
	}
	&.white {
		background: #fff;
		color: $roundup-primary !important;
	}
	&.border {
		border: 1px solid $roundup-primary;
		background: #fff;
		color: $roundup-primary !important;
	}
	&.danger {
		background: $roundup-red;
		color: #fff !important;
	}
	&.clear {
		background: transparent;
		box-shadow: none;
		color: #fff !important;
		border: 1px solid #fff;
		&:focus {
			outline: none;
		}
	}
	&:disabled {
		background: $roundup-medium-grey;
		cursor: default;
	}
	&.shadow {
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	}
	&.loading {
		padding-right: 55px;
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 0px;
			height: 0px;
			border-radius: 50%;
			right: 15px;
			top: 25px;
			border: 2px solid rgba(255, 255, 255, 0.8);
			border-right: 2px solid $roundup-primary;
			animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
		}
	}
	&.append {
		border-radius: 0px 20px 20px 0px;
	}
	@keyframes rotate360 {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes exist {
		100% {
			width: 12px;
			height: 12px;
			margin: -8px 5px 0 0;
		}
	}
}
</style>
