import axios from '../../network/axios'

import {
	REPORT_LIST,
	REPORT_LIST_REQUEST,
	REPORT_TYPE_SET,
	REPORT_GENERAL,
	TOGGLE_DEV_ENV,
	SET_DEV_ENV
} from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0,
	graph: null,
	report: null,
	general: {
		pendingDeposit: 0,
		donatedYTD: 0,
		newDonors: 0,
		totalDonors: 0
	},
	dev: window.localStorage.getItem('dev') === 'true' || false
}

const getters = {
	dev: () => window.localStorage.getItem('dev') === 'true' || false
}

// actions
const actions = {
	getGeneralReport({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.get('/reports?dev=' + window.localStorage.getItem('dev'))
				.then(({ data }) => {
					commit(REPORT_GENERAL, data)
					resolve()
				})
				.catch(reject)
		})
	},

	getNewTransactionsReport({ commit }, request) {
		return new Promise((resolve, reject) => {
			commit(REPORT_TYPE_SET, 'new-transactions')
			commit(REPORT_LIST_REQUEST)
			axios
				.get(
					'/reports/new-transactions?dev=' +
						window.localStorage.getItem('dev'),
					{ params: request }
				)
				.then(({ data }) => {
					commit(REPORT_LIST, data)
					resolve(data)
				})
				.catch(reject)
		})
	},

	getDonationsReport({ commit }, request) {
		return new Promise((resolve, reject) => {
			commit(REPORT_TYPE_SET, 'donations')
			commit(REPORT_LIST_REQUEST)
			axios
				.get(
					'/reports/payouts?dev=' +
						window.localStorage.getItem('dev'),
					{ params: request }
				)
				.then(({ data }) => {
					commit(REPORT_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},

	getProfileReport({ commit }, uuid) {
		const url =
			'https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A177138831&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Ausers&dimensions=ga%3AlandingPagePath%2Cga%3Adate&sort=ga%3Adate&filters=ga%3AlandingPagePath%3D%3D%2FRegister%2F3' +
			uuid +
			'&segment=gaid%3A%3A2u98iP6SRQ6KnCjOwU0PDg&access_token=ya29.GlsEBr1-OMHOVURy9YsFywuoR0p0yL243t4IlzQohVVqMImOzAAkoSBBtivfw_xQ9nqOwIdHdSZkaKP0ZkCIAqW-DEtYPtae49wICWCc6G0F-kHeFcUeAvBSS8_V'
		return new Promise((resolve, reject) => {
			commit(REPORT_TYPE_SET, 'profile')
			commit(REPORT_LIST_REQUEST)
			axios
				.get(url)
				.then(({ data }) => {
					commit(REPORT_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	},
	toggleDev({ commit, dispatch }) {
		commit(TOGGLE_DEV_ENV)
	},
	setDev({ commit, dispatch }, dev = true) {
		commit(SET_DEV_ENV, dev)
	}
}

const mutations = {
	[REPORT_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[REPORT_LIST](state, response) {
		state.isFetching = false
		state.items = response.results
		state.total = response.total
		state.graph = response.graph
	},
	[REPORT_TYPE_SET](state, type) {
		if (state.report !== type) {
			state.report = type
			state.items = []
			state.total = 0
			state.graph = null
		}
	},
	[REPORT_GENERAL](state, data) {
		state.general = data
	},
	[TOGGLE_DEV_ENV](state) {
		window.localStorage.setItem('dev', !state.dev)
		state.dev = !state.dev
		window.location.reload()
	},
	[SET_DEV_ENV](state, dev) {
		window.localStorage.setItem('dev', dev)
		state.dev = dev
		// without a delay, window will reload the same page rather than destination
		setTimeout(() => {
			window.location.reload()
		}, 300)
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
