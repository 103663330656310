import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { pluck, getUrlParams } from '../utilities'

Vue.use(Router)

const tableProps = params => {
	return route => {
		var props = route.query
		props.page = parseInt(route.query.page || 1)
		props.perPage = parseInt(route.query.perPage || 25)
		props.sortDir = route.query.sortDir
		props.sortBy = route.query.sortBy
		return Object.assign(props, pluck(route.params, params))
	}
}

const router = new Router({
	routes: [
		{
			path: '/',
			redirect: { name: 'dashboard' }
		},
		{
			path: '/Register',
			name: 'register',
			component: () => import('@/components/pages/Auth/RegisterView'),
			meta: { guest: true }
		},
		{
			path: '/steps',
			name: 'steps',
			component: () => import('@/components/pages/Onboarding/Steps')
		},
		// {
		// 	path: '/steps/cause',
		// 	name: 'select-cause',
		// 	component: () => import('@/components/pages/Onboarding/SelectCause')
		// },
		{
			path: '/cause',
			name: 'select-cause',
			component: () =>
				import('@/components/pages/Institution/InstitutionListView')
		},
		{
			path: '/search',
			name: 'search',
			component: () => import('@/components/pages/Search/SearchNonProfit')
		},
		{
			path: '/search/categories',
			name: 'search.categories',
			component: () => import('@/components/pages/Search/CategoriesView')
		},
		{
			path: '/search/results',
			name: 'search.results',
			component: () =>
				import('@/components/pages/Search/SearchResultsView')
		},
		{
			path: '/search/details/:uuid',
			name: 'search.details',
			component: () => import('@/components/pages/Search/DetailsView')
		},
		{
			path: '/steps/bank',
			name: 'select-bank',
			component: () => import('@/components/pages/Onboarding/ConnectBank')
		},
		{
			path: '/steps/update',
			name: 'step-update',
			component: () => import('@/components/pages/Onboarding/UpdateStep')
		},
		{
			path: '/steps/verify-deposits',
			name: 'step-verify-deposits',
			component: () =>
				import('@/components/pages/Onboarding/VerifyDeposits')
		},
		{
			path: '/api',
			name: 'api',
			component: () => import('@/components/pages/Developer/APITokens')
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('@/components/pages/Home/Dashboard')
		},
		{
			path: '/Resources/Library',
			name: 'library',
			component: () => import('@/components/pages/Marketing/Library')
		},
		{
			path: '/Congrats',
			name: 'congrats',
			component: () => import('@/components/pages/Auth/CongratsView'),
			meta: { guest: true }
		},
		{
			path: '/accept_TOS',
			name: 'TOS',
			component: () => import('@/components/pages/Auth/TOSView')
		},
		{
			path: '/verify',
			name: 'verify',
			component: () => import('@/components/pages/Auth/UpdateView')
		},
		{
			path: '/Login',
			name: 'login',
			component: () => import('@/components/pages/Auth/LoginView'),
			meta: { guest: true }
		},
		{
			path: '/login/:token',
			name: 'login-with-token',
			component: () => import('@/components/pages/Auth/LoginWithToken'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/Checkout',
			name: 'demo',
			component: () => import('@/components/pages/Demo/Checkout')
		},
		{
			path: '/',
			name: 'launch',
			component: () => import('@/components/pages/Auth/LaunchView'),
			meta: { guest: true }
		},
		{
			path: '/Invite/:uuid',
			name: 'invite',
			component: () => import('@/components/pages/Auth/InviteView'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/ForgotPassword',
			name: 'forgot-password',
			component: () =>
				import('@/components/pages/Auth/ForgotPasswordView'),
			meta: { guest: true }
		},
		{
			path: '/ResetPassword/:uuid',
			name: 'reset-password',
			component: () =>
				import('@/components/pages/Auth/ResetPasswordView'),
			meta: { guest: true },
			props: true
		},
		{
			path: '/Donors',
			name: 'donors',
			component: () => import('@/components/pages/Donors/DonorListView'),
			props: tableProps()
		},
		{
			path: '/Donors/:donorId',
			name: 'donors.detail',
			component: () =>
				import('@/components/pages/Donors/DonorDetailView'),
			props: tableProps(['donorId'])
		},
		{
			path: '/Reports',
			name: 'reports',
			component: () => import('@/components/pages/Reports/ReportsView')
		},
		{
			path: '/Reports/Transactions',
			name: 'reports.transactions',
			component: () =>
				import(
					'@/components/pages/Reports/NewTransactionsReportView.vue'
				),
			props: tableProps()
		},
		{
			path: '/Reports/Donations',
			name: 'reports.donations',
			component: () =>
				import('@/components/pages/Reports/DonationsReportView.vue'),
			props: tableProps()
		},
		{
			path: '/Reports/Views',
			name: 'reports.views',
			component: () =>
				import('@/components/pages/Reports/ProfileReportView.vue'),
			props: tableProps()
		},
		{
			path: '/Profile',
			name: 'profile',
			component: () =>
				import('@/components/pages/Profile/ProfileView.vue')
		},
		{
			path: '/Integrations/BigCommerce',
			name: 'integrations.bigcommerce',
			component: () =>
				import('@/components/pages/Profile/LinkBigCommerce.vue')
		},
		{
			path: '/Brand',
			name: 'brand',
			component: () => import('@/components/pages/Profile/CustomPage.vue')
		},
		{
			path: '/Users',
			name: 'users',
			component: () =>
				import('@/components/pages/Users/UserListView.vue'),
			props: tableProps()
		},
		{
			path: '/DonationForm',
			name: 'donation-form',
			component: () => import('@/components/pages/DonationForm/index.vue')
		},
		{
			path: '/Logout',
			name: 'logout',
			beforeEnter: (to, from, next) => {
				store.dispatch('logout').then(() => {
					next({ name: 'welcome' })
				})
			}
		}
	]
})

var shouldSetupRoute = (to, next) => {
	const params = getUrlParams(to.fullPath)

	if (store.getters.role === 0 || params.redirect === '0') {
		return false
	}
	// else if (!store.getters.hasStripe) {
	//     let uuid = store.getters.institutionUuid
	//     window.location.href = process.env.VUE_APP_stripeUrl + '&state=' + uuid
	//     return true
	// }
	// else if (!store.getters.approved) {
	//     if (to.name === 'congrats') {
	//         next()
	//         return true
	//     }
	//     next({ name: 'congrats' })
	//     return true
	// }
	// else if (!store.getters.hasTOS) {
	//     if (to.name === 'TOS') {
	//         next()
	//         return true
	//     }
	//     next({ name: 'TOS' })
	//     return true
	// }
	// if (!store.getters.isVerified) {
	//     if (to.name === 'verify') {
	//         next()
	//         return true
	//     }
	//     next({ name: 'verify' })
	//     return true
	// }

	return false
}

router.beforeEach((to, from, next) => {
	// Make sure you always let people login
	if (to.meta.guest && !store.state.me.isLoggedIn) {
		next()
		return
	}

	if (window.analytics) {
		window.analytics.page(to.fullPath, {
			referrer: window.location.origin + '/#' + from.path,
			url: window.location.origin + '/#' + to.fullPath
		})
	}

	// Ensure we have a user object before we start routing
	store.dispatch('checkUserLoad').then(
		() => {
			// Save previous page on every route change
			if (from.name !== 'login' && from.name !== 'welcome') {
				localStorage.setItem('prevPage', from.path)
			}

			if (to.name === 'logout') {
				next()
			}

			if (shouldSetupRoute(to, next)) {
				return
			}

			const params = getUrlParams(to.fullPath)

			// If you are trying to go to the login screen, send you to home
			if (
				to.meta.guest &&
				store.state.me.isLoggedIn &&
				params.redirect !== '0'
			) {
				if (to.path === '/' && store.getters.onboardingStep < 5) {
					next({ name: 'steps' })
					return
				}

				if (to.path === '/' && store.getters.onboardingStep >= 5) {
					next({ name: 'resources' })
					return
				}
				return
			}
			if (window.analytics) {
				window.analytics.identify(
					store.state.me.current.id,
					store.state.me.current
				)
			}

			if (typeof window !== 'undefined') {
				var _hsq = (window._hsq = window._hsq || [])

				_hsq.push([
					'identify',
					{
						email: store.state.me.current.email
					}
				])
			}

			// If everything is still golden, lets keep going
			next()
		},
		({ response }) => {
			next({
				name: 'launch'
			})
		}
	)
})

export default router
