<template>
	<div class="profile-dropdown">
		<div
			class="profile-item"
			v-if="institution"
			style="cursor: pointer"
			@click="showInstitutions = true"
		>
			<div class="profile-name">{{ institution.name }}</div>
		</div>

		<modal v-if="role < 1" v-model="showInstitutions" size="medium">
			<div style="flex: 1">
				<div class="merchant-search">
					<ui-input
						color="blue"
						v-model="query"
						placeholder="Search URL"
					>
					</ui-input>
				</div>
				<div class="merchant-list">
					<el-table
						slot="table"
						:data="merchantList"
						@row-click="onSwitchMerchant"
						style="width: 100%; cursor: pointer"
					>
						<el-table-column prop="name" label="name" />
						<el-table-column prop="url" label="URL">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Modal from '../ui/Modal'
import UiInput from '../ui/Input'
import Card from '../ui/Card'
import table from '@/mixins/table'

export default {
	name: 'profile-dropdown',
	components: { Modal, UiInput, Card },
	mixins: [table],
	props: {
		institution: Object
	},
	computed: {
		...mapGetters(['role', 'merchantList'])
	},
	data() {
		return {
			query: null,
			showInstitutions: false
		}
	},
	methods: {
		...mapActions(['listMerchants', 'switchMerchant']),
		onSwitchMerchant(row) {
			this.switchMerchant(row.id).then(() => {
				window.location = '/'
			})
		}
	},
	watch: {
		query: function(val) {
			this.listMerchants(val)
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.profile-dropdown {
	display: block;
	position: relative;
	padding-bottom: 18px;
	.profile-item {
		display: flex;
		align-items: center;
		justify-content: left;
		.profile-image {
			background: #fff;
			width: 50px;
			height: 50px;
			border-radius: 10px;
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
			overflow: hidden;
			flex-shrink: 0;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		.profile-name {
			max-width: 200px;
			padding-left: 10px;
			color: $roundup-medium-grey;
			font-weight: bold;
		}
	}
	.merchant-list {
		margin-top: 10px;
		max-height: 500px;
		overflow-y: scroll;
		li {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			padding: 15px 0;
			display: flex;
			text-align: left;
			list-style: none;
			cursor: pointer;
			&:hover {
				background: $roundup-darker-blue;
			}
			img {
				width: 40px;
				height: 40px;
				border-radius: 8px;
			}
			.item-text {
				padding-left: 15px;
				span {
					display: block;
					color: $white;
					&.item-subtitle {
						opacity: 0.5;
						font-size: 13px;
					}
				}
			}
		}
	}
	.merchant-search {
		position: relative;
		.loading {
			padding-right: 55px;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 0px;
				height: 0px;
				border-radius: 50%;
				right: 15px;
				top: 25px;
				border: 2px solid rgba(255, 255, 255, 0.8);
				border-right: 2px solid $roundup-navy;
				animation: rotate360 0.5s infinite linear,
					exist 0.1s forwards ease;
			}
		}
	}
}
</style>
