import axios from '../../network/axios'
import { get } from '../../utilities'

import {
	LOGIN,
	LOGOUT,
	USER_SET_CURRENT,
	USER_REMOVE_CURRENT,
	USER_SUCCESS_STEP,
	USER_SET_INSTITUTION,
	MERCHANT_DETAIL
} from '../types'

// initial state
const state = {
	current: null,
	token: localStorage.getItem('token'),
	isLoggedIn: !!localStorage.getItem('token')
}

const getters = {
	fullName: state => {
		return [
			get(state, 'current.first_name', ''),
			get(state, 'current.last_name', '')
		].join(' ')
	},
	isLoggedIn: state => state.isLoggedIn,
	hasUser: state => !!state.current,
	role: state =>
		state.current.role_id === 0
			? 0
			: state.current.role_id || Number.MAX_SAFE_INTEGER,
	isVerified: state => get(state, 'current.verified', false),
	me: state => state.current
}

const actions = {
	login({ commit, dispatch }, creds) {
		return new Promise((resolve, reject) => {
			axios.post('/login', creds).then(
				({ data }) => {
					window.analytics.track('login', {
						email: creds.email,
						source: 'web'
					})
					commit(USER_SET_CURRENT, data)
					if (typeof window !== 'undefined') {
						var _hsq = (window._hsq = window._hsq || [])

						_hsq.push([
							'identify',
							{
								email: data.email
							}
						])
					}

					commit(MERCHANT_DETAIL, data.merchant)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					resolve(data)
				},
				({ response }) => {
					if (response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Incorrect email or password combination. Please try again.'
							)
						)
					}
				}
			)
		})
	},
	loginWithToken({ commit, dispatch }, token) {
		return new Promise((resolve, reject) => {
			axios.post('/loginWithToken', { token }).then(
				({ data }) => {
					window.analytics.track('login_with_token', {
						email: data.email,
						source: 'web'
					})
					commit(USER_SET_CURRENT, data)
					if (typeof window !== 'undefined') {
						var _hsq = (window._hsq = window._hsq || [])

						_hsq.push([
							'identify',
							{
								email: data.email
							}
						])
					}

					commit(MERCHANT_DETAIL, data.merchant)
					commit(LOGIN, { token: data.token })
					dispatch('getCurrentUser')
					resolve(data)
				},
				({ response }) => {
					if (response.data) {
						reject(response.data)
					} else {
						reject(
							new Error(
								'Incorrect email or password combination. Please try again.'
							)
						)
					}
				}
			)
		})
	},

	logout({ commit }) {
		window.analytics.track('logout', { source: 'web' })
		return new Promise((resolve, reject) => {
			commit(LOGOUT)
			resolve()
		})
	},

	getCurrentUser({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			axios.get('/me').then(
				({ data }) => {
					commit(USER_SET_CURRENT, data)
					commit(MERCHANT_DETAIL, data.merchant)
					resolve()
				},
				({ response }) => {
					if (get(response, 'status', 200) === 401) {
						commit('LOGOUT')
					}
					reject(response)
				}
			)
		})
	},

	checkUserLoad({ commit, state, dispatch }) {
		if (state.current) {
			return new Promise((resolve, reject) => {
				resolve()
			})
		} else {
			return dispatch('getCurrentUser')
		}
	},

	register({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			axios.post('/register', user).then(
				({ data }) => {
					window.analytics.track('user_sign_up', {
						source: 'web',
						email: user.email
					})
					commit(MERCHANT_DETAIL, data.merchant)
					commit(USER_SET_CURRENT, data)
					commit(LOGIN, { token: data.token })
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	acceptInvite(context, user) {
		return new Promise((resolve, reject) => {
			axios.post('/invite', user).then(
				({ data }) => {
					window.analytics.track('merchant_accept_invite', {
						source: 'web'
					})
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	forgotPassword(context, email) {
		return new Promise((resolve, reject) => {
			axios
				.post('/password/forgot', {
					email: email,
					url: process.env.VUE_APP_URL
				})
				.then(
					({ data }) => {
						window.analytics.track('merchant_forgot_password', {
							source: 'web',
							email: email
						})
						resolve(data)
					},
					({ response }) => {
						reject(response.data)
					}
				)
		})
	},

	resetPassword(context, creds) {
		return new Promise((resolve, reject) => {
			axios.post('/password/reset', creds).then(
				({ data }) => {
					window.analytics.track('merchant_reset_password', {
						source: 'web',
						email: creds.email
					})
					resolve(data)
				},
				({ response }) => {
					reject(response.data)
				}
			)
		})
	},

	switchInstitution(context, institution) {
		return new Promise((resolve, reject) => {
			axios
				.post('/me/institution', { id: institution.id })
				.then(({ data }) => {
					window.analytics.track('user_switch_org', {
						source: 'web',
						new_org: institution.id
					})
					location.reload()
				})
		})
	}
}

// mutations
const mutations = {
	[LOGIN](state, tokens) {
		state.isLoggedIn = true
		state.token = tokens.token

		// Store token as well as set it for axios use
		localStorage.setItem('token', tokens.token)
	},

	[LOGOUT](state) {
		state.isLoggedIn = false
		state.token = null
		state.current = null

		// Remove local store
		localStorage.removeItem('token')

		// window.location = '/Login'
	},
	[USER_SET_CURRENT](state, user) {
		state.current = user

		// Vue.prototype.$intercom.boot({
		//     user_id: user.id,
		//     name: user.first_name + ' ' + user.last_name,
		//     email: user.email
		// })
	},
	[USER_SET_INSTITUTION](state, institution) {
		state.current.institution = institution
	},
	[USER_REMOVE_CURRENT](state, user) {
		state.current = null
	},
	[USER_SUCCESS_STEP](state, step) {
		state.current[step] = true
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}
