<template>
	<div class="roundup-toggle">
		<input
			:checked="checked"
			@change="$emit('input', $event.target.value)"
			type="checkbox"
			:disabled="disabled"
			:class="[disabled && 'disabled']"
			:id="key"
		/>
		<label :for="key" :class="[color && color]"></label>
	</div>
</template>

<script>
export default {
	name: 'roundup-toggle',
	props: ['checked', 'disabled', 'color'],
	computed: {
		key() {
			return 'toggle-' + this.makeid()
		}
	},
	methods: {
		makeid() {
			var text = ''
			var possible =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			for (var i = 0; i < 8; i++) {
				text += possible.charAt(
					Math.floor(Math.random() * possible.length)
				)
			}
			return text
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.roundup-toggle {
	position: relative;
	input[type='checkbox'] {
		height: 0;
		width: 0;
		visibility: hidden;
		position: absolute;
	}
	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 44px;
		height: 26px;
		background: #808080;
		display: block;
		border-radius: 13px;
		position: relative;
		cursor: default;
		&:after {
			content: '';
			position: absolute;
			top: 1px;
			left: 1px;
			width: 24px;
			height: 24px;
			background: #fff;
			border-radius: 12px;
			transition: 0.3s;
		}
	}
	input {
		&:checked {
			label {
				background: $roundup-alt-green;
				&:after {
					left: calc(100% - 1px);
					transform: translateY(-100%);
				}
			}
		}
	}
}
.roundup-toggle.disabled,
.roundup-toggle input:checked + label {
	background: $roundup-alt-green;
}
</style>
