<template>
	<div class="app roundup-merchant-app">
		<v-app>
			<layout v-if="hasUser"></layout>
			<auth-layout v-else></auth-layout>
		</v-app>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { getUrlParams } from './utilities'
import Layout from './components/layout/Layout'
import AuthLayout from './components/layout/AuthLayout'
export default {
	name: 'app',
	components: {
		AuthLayout,
		Layout
	},
	computed: {
		...mapState({
			isLoggedIn: ({ me }) => me.isLoggedIn
		}),
		...mapGetters(['hasUser', 'approved', 'role', 'dev'])
	},
	methods: {
		...mapActions(['updateQuery']),

		onShowDetails(inst) {
			this.updateQuery(inst.name)
			this.$router.push({
				name: 'search.details',
				props: { uuid: inst.uuid },
				params: { uuid: inst.uuid, inst }
			})
		},
		onShowResults(params) {
			this.updateQuery(params.query)
			this.$router.push({
				name: 'search.results',
				params
			})
		}
	},
	mounted() {
		this.$root.$on('search', query => this.onShowResults({ query }))
		this.$root.$on('searchDetail', inst => this.onShowDetails(inst))
		this.$root.$on('localSearch', query => this.onShowResults({ query }))
		const params = getUrlParams(window.location.href)

		let trackParams = {}

		if (params.store_info) {
			sessionStorage.setItem('store_info', decodeURI(params.store_info))
			trackParams.siteInfo = JSON.parse(decodeURI(params.store_info))
		}
		if (params.access_token) {
			sessionStorage.setItem('bc_ru_token', params.access_token)
			trackParams.access_token = params.access_token
		}
		if (params.store_hash) {
			trackParams.storeHash = params.store_hash
			window.analytics.track('bc_store_load', trackParams)

			sessionStorage.setItem('bc_ru_store', params.store_hash)
			if (this.isLoggedIn) {
				window.location.replace('/#/Integrations/BigCommerce')
			} else {
				window.location.replace('/')
			}
		}
	}
}
</script>

<style lang="scss">
@import 'styles/font.scss';
@import '@roundupapp/component-library/src/styles/index';

* {
	box-sizing: border-box;
	padding: revert;
	margin: initial;
}
body,
html {
	.dev-wrapper {
		border: 5px solid $roundup-mint-highlight;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	background: $roundup-lighter-grey;
	@media screen and (max-width: 480px) {
		height: 100%;
		.swal2-shown {
			overflow-y: visible !important;
		}
	}
}

#app,
.roundup-merchant-app,
.roundup-merchant-app > .v-application > .v-application--wrap {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: $roundup-bone;
}

.swal2-toast {
	display: block !important;
	max-width: 500px;
	.swal2-title {
		margin: 0 0 10px 0 !important;
		text-align: left;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23184fd5'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-size: 1.5rem 1.5rem;
		padding-left: 25px !important;
	}
}
.swal2-content {
	#swal2-content {
		text-align: left;
	}
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible) > svg,
.v-progress-circular--indeterminate:not(.v-progress-circular--visible)
	.v-progress-circular__overlay {
	animation-play-state: inherit !important;
}
</style>
