// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import navigate from './mixins/navigate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMoment from 'vue-moment'
import VueHead from 'vue-head'
import VueGtm from 'vue-gtm'

import Components from '@roundupapp/component-library'

import {
	Button,
	Select,
	Table,
	TableColumn,
	Pagination,
	DatePicker,
	Tag
} from 'element-ui'
import language from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

for (var k in Components) {
	if (!k.includes('config')) {
		Vue.component(k, Components[k])
	}
}

Vue.use(navigate)
Vue.use(VueSweetalert2)
Vue.use(VueHead)
Vue.use(VueMoment)

Vue.use(VueGtm, {
	id: process.env.VUE_APP_gtm,

	// queryParams: {
	// 	Add url query string when load gtm.js with GTM ID (optional)
	// 	gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
	// 	gtm_preview: 'env-4',
	// 	gtm_cookies_win: 'x'
	// },
	defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: process.env.NODE_ENV !== 'production', // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	// ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
	trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

locale.use(language)
Vue.use(Button)
Vue.use(Select)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Pagination)
Vue.use(DatePicker)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
