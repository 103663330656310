var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sidebar"},[_c('nav',{staticStyle:{"position":"fixed","top":"-20","left":"-20"}},[_c('profile-dropdown',{attrs:{"institution":_vm.merchant}}),(_vm.isOnboarding)?_c('li',[_c('div',{staticClass:"getStarted-wrapper"},[_c('router-link',{staticClass:"getStarted-header main-menu-item",attrs:{"to":{ name: 'steps' }}},[_vm._v(" Getting Started ")]),_c('div',{staticClass:"getStarted-menu"},[_c('router-link',{attrs:{"to":{ name: 'select-cause' }}},[(!_vm.merchant.institution)?_c('i',{staticClass:"fa fa-circle"}):_vm._e(),(_vm.merchant.institution)?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e(),_c('span',[_vm._v("Cause")])]),_c('router-link',{class:{ disabled: !_vm.merchant.institution },attrs:{"to":{ name: 'select-bank' }}},[(
								!(
									_vm.merchant.payment_verified &&
									_vm.merchant.payment_last_4
								)
							)?_c('i',{staticClass:"fa fa-circle"}):_vm._e(),(
								_vm.merchant.payment_verified &&
									_vm.merchant.payment_last_4
							)?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e(),_c('span',[_vm._v("Donation Source")])])],1)],1)]):_vm._e(),_c('li',[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('i',{staticClass:"fas fa-home"}),_c('span',{staticClass:"main-menu-item"},[_vm._v("Home")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'reports' }}},[_c('i',{staticClass:"fas fa-chart-bar"}),_c('span',{staticClass:"main-menu-item"},[_vm._v("General Reports")])]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'reports.donations' }}},[_c('span',[_vm._v("Donations")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'reports.transactions' }}},[_c('span',{staticStyle:{"margin-top":"0px"}},[_vm._v("RoundUps")])])],1)])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'select-cause' }}},[_c('i',{staticClass:"fas fa-flag"}),_c('span',{staticClass:"main-menu-item"},[_vm._v("Your Cause")])]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'search' }}},[_c('span',{staticStyle:{"margin-top":"0px"}},[_vm._v("Nonprofits")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'donation-form' }}},[_c('span',{staticStyle:{"margin-top":"0px"}},[_vm._v("Donation Form")])])],1)])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'api' }}},[_c('i',{staticClass:"fas fa-code"}),_c('span',{staticClass:"main-menu-item"},[_vm._v("API Keys")])]),_c('ul',[_c('router-link',{attrs:{"to":{ name: 'demo' }}},[_c('span',[_vm._v("Checkout Demo")])])],1)],1),_c('div',{staticStyle:{"position":"fixed","left":"25","bottom":"60px"}},[_c('a',{style:({
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginLeft: '5px'
				})},[_c('RoundupToggle',{style:({ flex: 1 }),attrs:{"checked":_vm.dev},on:{"change":_vm.devCheck}}),_c('p',{style:({
						flex: 4,
						marginLeft: '5px',
						fontSize: '14px',
						marginBottom: '0px',
						fontWeight: 600,
						color: '#71788c'
					})},[_vm._v(" Test Data ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'profile' }}},[_c('i',{staticClass:"fas fa-cog"}),_c('span',{staticClass:"main-menu-item"},[_vm._v("Settings")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'logout' }}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_c('span',{staticClass:"main-menu-item"},[_vm._v("Logout")])])],1)])],1),_c('div',{staticClass:"roundup-logo"})])}
var staticRenderFns = []

export { render, staticRenderFns }