import axios from '../../network/axios'
import { UPDATE_SEARCH_QUERY, UPDATE_SEARCH_FILTERS } from '../types.js'

const state = {
	items: [],
	filters: {
		category: null,
		subcategory: null,
		location: {
			city: null,
			state: null
		},
		zip: null,
		partner: null,
		query: null
	},
	total: 0
}

const getters = {
	searchListResults: state => state.items,
	searchListQuery: state => state.filters.query,
	searchListFilters: state => state.filters
}

// actions
const actions = {
	clearSearch({ commit }) {
		commit(UPDATE_SEARCH_QUERY, '')
	},
	updateQuery({ commit }, query) {
		commit(UPDATE_SEARCH_QUERY, query)
	},
	updateFilters({ commit }, filters) {
		commit(UPDATE_SEARCH_FILTERS, filters)
	}
}

const mutations = {
	[UPDATE_SEARCH_QUERY](state, response) {
		state.filters.query = response
	},
	[UPDATE_SEARCH_FILTERS](state, response) {
		state.filters = { ...state.filters, ...response }
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
