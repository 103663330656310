<template>
	<section class="card">
		<div class="card-header" v-if="hasTitleSlot">
			<div class="card-title">
				<slot name="title"></slot>
				<slot name="subtitle"></slot>
			</div>
			<div class="card-buttons">
				<slot name="buttons"></slot>
			</div>
		</div>
		<div class="card-chart" v-if="hasChartSlot">
			<slot name="chart"></slot>
		</div>
		<div class="card-table" v-if="hasTableSlot">
			<slot name="table"></slot>
		</div>
		<div class="card-body" v-if="hasBodySlot">
			<slot name="body"></slot>
		</div>
		<div class="card-footer">
			<slot name="footer"></slot>
		</div>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'roundup-card',
	props: ['size'],
	computed: {
		hasTitleSlot() {
			return !!this.$slots['title']
		},
		hasTableSlot() {
			return !!this.$slots['table']
		},
		hasChartSlot() {
			return !!this.$slots['chart']
		},
		hasBodySlot() {
			return !!this.$slots['body']
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
@import url('//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css');

.card {
	background: $white;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	margin: 20px 10px 20px 10px;
	padding: 20px;
	border-radius: 14px;
	overflow: hidden;
	&.small {
		max-width: 420px;
	}
	&.medium {
		max-width: 600px;
	}
	&.large {
		max-width: 1280px;
	}
	.card-header {
		display: flex;
		margin: -20px -20px 20px -20px;
		padding: 15px 20px 15px 20px;
		border-bottom: 1px solid $roundup-light-grey;
		.card-title {
			flex-grow: 1;
			h1 {
				font-size: 24px;
				font-weight: 600;
				margin-top: 5px;
				margin-bottom: 0px;
				color: $roundup-medium-grey;
			}
		}
		.card-buttons {
			flex-grow: 0;
			flex-shrink: 0;
			button {
				margin-top: 0px;
			}
		}
	}
	.card-body {
		padding: 0px 40px;
		max-height: 100%;
		overflow-y: auto;
		padding-bottom: 5px;
	}
	.card-table {
		position: relative;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 40px;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 48px;
			top: -1px;
			left: 0px;
			background: $roundup-lightest-grey;
			border-top: 1px solid $roundup-light-grey;
			border-bottom: 1px solid $roundup-lighter-grey;
		}
	}
	.el-table .cell {
		word-break: inherit;
	}
	.el-table__header-wrapper {
		position: relative;
		tr,
		th {
			background: $roundup-lightest-grey;
			text-transform: uppercase;
			.cell {
				word-break: inherit;
				font-size: 12px;
			}
		}
	}
	.el-table--enable-row-hover .el-table__body {
		tr {
			td {
				transition: none;
				cursor: pointer;
			}
			&:hover {
				border-radius: 5px;
				overflow: hidden;
				td:first-child {
					border-top-left-radius: 10px;
				}
				td:last-child {
					border-top-right-radius: 10px;
				}
				td:first-child {
					border-bottom-left-radius: 10px;
				}
				td:last-child {
					border-bottom-right-radius: 10px;
				}
				> td {
					background: #f4f7fd;
					color: $roundup-charcoal;
				}
			}
		}
	}
	.card-chart {
		background: $roundup-lightest-grey;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 20px;
		border-bottom: 1px solid $roundup-light-grey;
		min-height: 300px;
		.chart-options {
			padding: 10px 20px 0px;
		}
	}
	.card-footer {
		margin-top: 20px;
		text-align: center;
	}
}
</style>
