<template>
	<div
		class="roundup-input"
		:class="[
			color ? 'input-type--' + color : '',
			variation ? 'input-variation--' + variation : '',
			size ? 'input-size--' + size : '',
			icon ? 'input-padding' : ''
		]"
	>
		<div
			class="roundup-input-icon"
			:class="[icon ? icon : '']"
			v-if="icon"
		></div>
		<textarea
			@focus="resize"
			id="textarea"
			ref="textarea"
			:rows="rows"
			placeholder=" "
			v-bind:value="value"
			v-on:input="$emit('input', $event.target.value)"
			v-if="type == 'textarea'"
			:style="{ fontFamily: 'inherit' }"
		></textarea>
		<input
			v-else
			:type="type"
			ref="input"
			id="input"
			placeholder=" "
			:readonly="readonly"
			:maxlength="maxlength"
			:minlength="minlength"
			:disabled="disabled"
			:autocomplete="autocorrect ? 'on' : 'off'"
			:autocorrect="autocorrect ? 'on' : 'off'"
			:autocapitalize="autocorrect ? 'on' : 'off'"
			:spellcheck="autocorrect"
			v-bind:value="value"
			v-on:input="$emit('input', $event.target.value)"
		/>
		<span
			class="roundup-float-label"
			:class="[icon ? 'input-padding' : '']"
			>{{ placeholder }}</span
		>
		<div
			class="roundup-input-counter"
			:style="{ color: 'red' }"
			v-if="minlength && value.length < minlength"
		>
			min. {{ minlength }} characters
		</div>
		<div class="roundup-input-counter" v-else-if="maxlength">
			{{ value ? value.length : 0 }}/{{ maxlength }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'roundup-input',
	props: {
		color: String,
		variation: String,
		size: String,
		icon: String,
		value: {
			type: [String, Number],
			default: ''
		},
		rows: {
			type: Number,
			default: 1
		},
		maxlength: {
			type: Number,
			default: null
		},
		minlength: {
			type: Number,
			default: null
		},
		readonly: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		autosize: {
			type: Boolean,
			default: true
		},
		autocorrect: {
			type: Boolean,
			default: true
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		type: String,
		placeholder: '',
		minHeight: {
			type: Number,
			default: 20
		}
	},
	computed: {
		computedStyles() {
			let objStyles = {}
			if (this.autosize) {
				objStyles.resize = 'none'
			}
			return objStyles
		}
	},
	methods: {
		resize: function() {
			if (this.type !== 'textarea') return
			this.$refs['textarea'].style.setProperty('height', 'auto')
			let contentHeight = this.$refs['textarea'].scrollHeight + 1
			if (this.minHeight) {
				contentHeight =
					contentHeight < this.minHeight
						? this.minHeight
						: contentHeight
			}
			const heightVal = contentHeight + 'px'
			this.$refs['textarea'].style.setProperty('height', heightVal)
			return this
		}
	},
	mounted() {
		if (this.type === 'textarea') this.resize()
		if (this.autofocus) this.$refs['input'].focus()
	},
	watch: {
		value() {
			this.$nextTick(this.resize)
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.roundup-input {
	& > span {
		position: absolute;
		bottom: 0em;
		left: 0em;
		cursor: text;
		font-size: 75%;
		opacity: 1;
		transition: all 0.2s;
		color: $roundup-medium-grey;
	}
	.input-padding {
		padding-left: 25px;
	}
	#input {
		&:placeholder-shown {
			&:not(:focus)::placeholder {
				opacity: 0;
			}
			&:not(:focus) {
				& + * {
					font-size: 100%;
					color: $roundup-medium-grey;
					bottom: 1.6em;
				}
			}
			&:focus {
				& + * {
					color: $roundup-primary;
				}
			}
		}
		&:disabled {
			background: $roundup-lightest-grey;
			color: $roundup-dark-grey;
			&:hover {
				border-bottom-color: $roundup-light-grey;
			}
		}
	}
	#textarea {
		&:placeholder-shown {
			&:not(:focus)::placeholder {
				opacity: 0;
			}
			&:not(:focus) {
				& + * {
					font-size: 100%;
					color: $roundup-medium-grey;
					bottom: 1.6em;
				}
			}
			&:focus {
				& + * {
					color: $roundup-primary;
				}
			}
		}
		&:disabled {
			background: $roundup-lightest-grey;
			color: $roundup-dark-grey;
			&:hover {
				border-bottom-color: $roundup-light-grey;
			}
		}
	}
	#input[readonly] {
		pointer-events: none;
	}
	#textarea[readonly] {
		pointer-events: none;
	}
	&.input-type--blue {
		background: $roundup-light-grey;
		border-width: 0px;
		width: 100%;
		font-size: $font-size;
		padding: 0px 20px 15px 15px;
		outline: none;
		-webkit-appearance: none;
		appearance: none;
		border-radius: 10px;
		color: $white;
		background: $roundup-lighter-grey;
		caret-color: $roundup-primary;
		&:hover {
			background: $roundup-lighter-grey;
		}
		&::placeholder {
			color: $roundup-medium-grey;
		}
		&::-webkit-input-placeholder {
			color: $roundup-medium-grey;
		}
		&::-moz-placeholder {
			color: $roundup-medium-grey;
		}
		input {
			background: $roundup-lighter-grey;
			&:hover {
				background: $roundup-lighter-grey;
			}
		}
		& > span {
			left: 1em;
		}
	}
	&.input-size--small {
		input {
			padding-top: 5px;
			padding-bottom: 5px;
		}
		&.input-variation--border {
			input {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		&.input-variation--blank {
			input {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
	&.input-variation--border {
		input {
			border-radius: 4px;
			padding-left: 10px;
			padding-right: 10px;
			border: 1px solid $roundup-medium-grey;
		}
	}
	&.input-variation--blank {
		input {
			border: 1px solid transparent;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.roundup-input-counter {
		position: absolute;
		right: 0px;
		bottom: 1.5em;
		border: 1px solid $roundup-light-grey;
		color: $roundup-dark-grey;
		border-radius: 10px;
		padding: 3px 7px;
	}
	.roundup-input-icon {
		position: absolute;
		left: 0;
		top: 17px;
		z-index: 999;
		height: 20px;
		width: 20px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		&.search {
			background-image: url('../../assets/search.png');
		}
	}
}
.roundup-input #input,
.roundup-input #textarea {
	color: $roundup-primary;
	border-width: 0px;
	border-bottom: 1px solid $roundup-light-grey;
	width: 100%;
	font-size: $font-size;
	padding: 10px 0px;
	outline: none;
	-webkit-appearance: none;
	border-radius: 0px;
	padding-top: 1em;
}
.roundup-input #input:hover,
.roundup-input #textarea:hover,
.roundup-input #input:focus,
.roundup-input #textarea:focus {
	border-bottom-color: $roundup-primary;
	outline: none;
}
.roundup-input #input::-webkit-input-placeholder,
.roundup-input #textarea::-webkit-input-placeholder,
.roundup-input #input::-ms-input-placeholder,
.roundup-input #textarea::-ms-input-placeholder {
	color: $roundup-medium-grey;
}
.roundup-input #input::placeholder,
.roundup-input #textarea::placeholder {
	opacity: 1;
	transition: all 0.2s;
}
</style>
