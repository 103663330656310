import axios from '../../network/axios'
import { analytics } from '../../utilities/tracker.js'
import { EMBED_DETAIL, EMBED_DETAIL_SET_ACTIVE_ORG_UUID } from '../types.js'

const state = {
	isFetching: false,
	item: null,
	activeOrgUUID: null
}

// actions
const actions = {
	getEmbedForm({ commit, state }) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/embed-form/${state.activeOrgUUID}`)
				.then(result => {
					let resultData = result.data || null
					commit(EMBED_DETAIL, resultData)
					resolve()
				})
				.catch(reject)
		})
	},
	createOrUpdateEmbed({ dispatch, state }, embedForm) {
		return new Promise((resolve, reject) => {
			axios
				.put('/embed-form' + '/' + state.activeOrgUUID, embedForm)
				.then(() => {
					analytics.track('create_or_update_embed', embedData)
					dispatch('getEmbed')
				})
				.then(() => {
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[EMBED_DETAIL](state, data) {
		state.isFetching = false
		state.item = data
	},
	[EMBED_DETAIL_SET_ACTIVE_ORG_UUID](state, uuid) {
		state.activeOrgUUID = uuid
	}
}

export default {
	state,
	actions,
	mutations
}
