import axios from '../../network/axios'

import { EVENTS_LIST_SET, EVENTS_LIST_RESET, QUERYID_SET } from '../types'

const state = {
	events: [],
	userToken: localStorage.getItem('search-token'),
	indexName: 'causes',
	queryID: null
}

const actions = {
	setEvent({ commit, state, dispatch }, payload) {
		const { data, immediate } = payload
		commit(EVENTS_LIST_SET, data)
		if (state.events.length >= 5 || immediate) {
			dispatch('sendEventList')
		}
	},
	setClickEvent({ state, dispatch }, payload) {
		let { eventName, objectIDs, positions, filters } = payload

		let clickEvent = {
			eventType: 'click',
			eventName,
			userToken: state.userToken,
			index: state.indexName,
			timestamp: Date.now()
		}
		if (positions && positions.length > 0) {
			clickEvent.positions = positions.map(el => parseInt(el) + 1)
		}

		if (objectIDs && objectIDs.length > 0) {
			clickEvent.objectIDs = objectIDs.map(el => el.toString())
		}

		if (state.queryID && clickEvent.positions && clickEvent.objectIDs) {
			clickEvent.queryID = state.queryID
		}

		if (filters && filters.length > 0 && !clickEvent.objectIDs) {
			clickEvent.filters = filters
		}

		dispatch('setEvent', { data: clickEvent, immediate: false })
	},
	setViewEvent({ state, dispatch }, payload) {
		let { eventName, filters } = payload
		let viewEvent = {
			eventType: 'view',
			eventName,
			userToken: state.userToken,
			index: state.indexName,
			timestamp: Date.now()
		}

		if (filters && filters.length > 0) {
			viewEvent.filters = filters
		}

		dispatch('setEvent', { data: viewEvent, immediate: false })
	},
	setConversionEvent({ state, dispatch }, payload) {
		let { eventName, filters } = payload
		let conversionEvent = {
			eventType: 'conversion',
			eventName,
			userToken: state.userToken,
			index: state.indexName,
			timestamp: Date.now()
		}

		if (filters && filters.length > 0) {
			conversionEvent.filters = filters
		}

		dispatch('setEvent', { data: conversionEvent, immediate: true })
	},
	setSend({ commit }, payload) {
		commit(SEND_SET, payload)
	},
	setQueryID({ commit }, payload) {
		commit(QUERYID_SET, payload)
	},
	sendEventList({ commit, state }) {
		return new Promise((resolve, reject) => {
			if (state.events.length === 0) {
				resolve()
			} else {
				axios
					.post('/api/search/analytics', state.events)
					.then(() => commit(EVENTS_LIST_RESET, []))
					.then(() => resolve())
					.catch(e => console.log('error sending event list', e))
			}
		})
	}
}

const mutations = {
	[EVENTS_LIST_SET](state, event) {
		state.events = [...state.events, event]
	},
	[EVENTS_LIST_RESET](state) {
		state.events = []
	},
	[QUERYID_SET](state, value) {
		state.queryID = value
	}
}

export default {
	state,
	mutations,
	actions
}
