import axios from '../../network/axios'
import {
	GET_MARKETING_LIBRARY,
	GET_MARKETING_LIBRARY_SUCCESS
} from '../types.js'

const state = {
	isFetching: false,
	items: {
		main: {
			objects: []
		},
		facebook: {
			objects: []
		},
		video: {
			objects: []
		},
		website: {
			objects: []
		},
		email: {
			objects: []
		}
	}
}

const getters = {}

// actions
const actions = {
	getLibrary({ commit }) {
		return new Promise((resolve, reject) => {
			commit(GET_MARKETING_LIBRARY)
			axios
				.get('/marketing')
				.then(response => {
					commit(GET_MARKETING_LIBRARY_SUCCESS, response)
					resolve(response.data)
				})
				.catch(err => console.log(err))
		})
	}
}

const mutations = {
	[GET_MARKETING_LIBRARY](state) {
		state.isFetching = true
	},
	[GET_MARKETING_LIBRARY_SUCCESS](state, response) {
		state.isFetching = false
		state.items = response.data
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
