import axios from '../../network/axios'
import { DONOR_LIST, DONOR_LIST_REQUEST } from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0
}

const getters = {}

// actions
const actions = {
	getDonors({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(DONOR_LIST_REQUEST)
			axios
				.get('/donors', { params })
				.then(({ data }) => {
					commit(DONOR_LIST, data)
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[DONOR_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[DONOR_LIST](state, response) {
		state.isFetching = false
		state.items = response.results
		state.total = response.total
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
