import axios from '../../network/axios'
import { get } from '../../utilities'
import {
	MERCHANT_DETAIL_REQUEST,
	MERCHANT_DETAIL,
	MERCHANT_HOME,
	MERCHANT_EMBED,
	INSTITUTION_DETAIL
} from '../types'

const state = {
	isFetching: false,
	item: null,
	registerList: [],
	home: {
		newRoundUps: 0,
		totalRoundUps: 0,
		pendingDeposit: 0,
		lastDonation: 0,
		lastDonationDate: null,
		pendingDonations: []
	},
	embed: {
		institution: {
			name: null
		}
	}
}

const getters = {
	hasStripe: state => get(state, 'item.stripe_source', false) && true,
	merchant: state => get(state, 'item', null),
	multiplier: state => get(state, 'item.multiplier'),
	onboardingStep: state => {
		const hasInst = !!get(state, 'item.institution')
		const hasStripe = !!get(state, 'item.stripe_source')

		return hasInst + hasStripe
	},
	home: state => get(state, 'home'),
	institution: state => get(state, 'home.institution'),
	lastDonationDate: state =>
		new Date(get(state, 'home.lastDonationDate')).toLocaleDateString(),
	donationSums: state => {
		let totalMatch = 0
		let totalRoundUp = 0
		get(state, 'home.pendingDonations').forEach(d => {
			totalMatch += d.match_donation || 0
			totalRoundUp += d.expected_donation || 0
		})
		totalMatch = parseFloat(((totalMatch * 100) / 100).toFixed(2))
		totalRoundUp = parseFloat(((totalRoundUp * 100) / 100).toFixed(2))
		return { totalMatch, totalRoundUp }
	},
	matchPaused: state => get(state, 'item.match_paused', false),
	embed: state => get(state, 'embed')
}

// actions
const actions = {
	getHome({ commit }) {
		return new Promise((resolve, reject) => {
			commit(MERCHANT_DETAIL_REQUEST)
			axios
				.get('/home')
				.then(({ data }) => {
					commit(MERCHANT_HOME, data)
					resolve()
				})
				.catch(err => reject(err))
		})
	},
	getMerchantDetails({ commit }) {
		return new Promise((resolve, reject) => {
			commit(MERCHANT_DETAIL_REQUEST)
			axios
				.get('/details')
				.then(({ data }) => {
					commit(MERCHANT_DETAIL, data)
					resolve(data)
				})
				.catch(reject)
		})
	},
	updateMerchant({ commit }, institution) {
		return new Promise((resolve, reject) => {
			axios
				.put('/institutions', institution)
				.then(({ data }) => {
					window.analytics.track('org_updated')
					commit(MERCHANT_DETAIL, data)
					resolve()
				})
				.catch(err => {
					console.log(err)
					reject(err)
				})
		})
	},
	addPlaid({ commit }, body) {
		return new Promise((resolve, reject) => {
			commit(MERCHANT_DETAIL_REQUEST)
			axios
				.post('/plaid', body)
				.then(({ data }) => {
					commit(MERCHANT_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	getPublicToken() {
		return new Promise((resolve, reject) => {
			axios
				.get('/plaid/token')
				.then(({ data }) => {
					resolve(data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	deleteLink({ commit }) {
		return new Promise((resolve, reject) => {
			axios
				.delete('/plaid')
				.then(({ data }) => {
					window.analytics.track('delete_linked_account')
					commit(MERCHANT_DETAIL, data)
					resolve(data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},

	refreshLink({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios
				.post('/plaid/verify', data)
				.then(({ data }) => {
					window.analytics.track('bank_account_verified')
					resolve(data)
					commit(MERCHANT_DETAIL, data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateTOS({ commit }, body) {
		return new Promise((resolve, reject) => {
			axios
				.put('/institutions/tos', body)
				.then(({ data }) => {
					commit(MERCHANT_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	rotateToken({ commit }, type) {
		return new Promise((resolve, reject) => {
			axios
				.put('/token/' + type)
				.then(({ data }) => {
					commit(MERCHANT_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	},
	saveTransaction({ dispatch }, body) {
		return new Promise((resolve, reject) => {
			axios
				.post('/transactions', body)
				.then(data => {
					dispatch('getGeneralReport')
					resolve()
				})
				.catch(err => reject(err))
		})
	},
	update({ commit }, body) {
		return new Promise((resolve, reject) => {
			axios
				.put('/update', body)
				.then(data => {
					commit(MERCHANT_DETAIL, data.data)
					resolve()
				})
				.catch(reject)
		})
	},
	linkBigCommerce({ state }, isDev) {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VUE_APP_baseUrl}/bigcommerce/link`, {
					api_key: isDev
						? state.item.dev_api_key
						: state.item.api_key,
					access_token: state.item.embed_access_token
						? state.item.embed_access_token
						: sessionStorage.getItem('bc_ru_token'),
					store_hash: state.item.embed_id
						? state.item.embed_id
						: sessionStorage.getItem('bc_ru_store')
				})
				.then(() => {
					sessionStorage.removeItem('bc_ru_token')
					sessionStorage.removeItem('bc_ru_store')

					resolve()
				})
				.catch(reject)
		})
	},
	getEmbed({ commit, state }) {
		return new Promise(resolve => {
			axios.get(`/embed/${state.item.id}`).then(data => {
				commit(MERCHANT_EMBED, data.data)
				resolve()
			})
		})
	},
	updateEmbedAmount({ dispatch }, options) {
		return new Promise((resolve, reject) => {
			axios
				.put(`/embed/amount`, options)
				.then(async () => {
					await dispatch('getMerchantDetails')
					resolve()
				})
				.catch(() => {
					reject()
				})
		})
	},
	pauseDonations({ dispatch, commit }) {
		return new Promise((resolve, reject) => {
			axios
				.put('/pause')
				.then(data => {
					dispatch('listMyInstitutions')
					commit(INSTITUTION_DETAIL, null)
					commit(MERCHANT_DETAIL, data.data)
					resolve()
				})
				.catch(reject)
		})
	},
	fetchLinkToken() {
		return new Promise((resolve, reject) => {
			axios
				.post('/plaid/token')
				.then(({ data }) => resolve(data))
				.catch(err => reject(err))
		})
	}
}

const mutations = {
	[MERCHANT_DETAIL_REQUEST](state) {
		state.isFetching = true
	},
	[MERCHANT_DETAIL](state, merchant) {
		state.isFetching = false
		state.item = merchant
	},
	[MERCHANT_HOME](state, data) {
		state.isFetching = false
		state.home = data
	},
	[MERCHANT_EMBED](state, data) {
		state.embed = data
	}
}

export default {
	state,
	actions,
	getters,
	mutations
}
