/* eslint-disable camelcase */
import axios from '../../network/axios'
import { get } from '../../utilities'
import {
	INSTITUTION_DETAIL,
	INSTITUTION_DETAIL_REQUEST,
	INSTITUTION_LIST_DETAIL,
	MERCHANT_DETAIL
} from '../types'

const state = {
	isFetching: false,
	item: null,
	registerList: [],
	list: []
}

const getters = {
	institutionUuid: state => get(state, 'item.uuid', null),
	institutionList: state => {
		var active = []
		var rejected = []
		var list = []
		if (!state.list.length) {
			return { active, list, rejected }
		}
		active = state.list.filter(inst => inst.is_active)
		list = state.list.filter(inst =>
			['Pending', 'Consented'].includes(inst.institution_consent)
		)
		rejected = state.list.filter(
			inst => !['Pending', 'Consented'].includes(inst.institution_consent)
		)

		return { active, list, rejected }
	}
}

// actions
const actions = {
	getInstitutionDetail({ commit }, id) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_DETAIL_REQUEST)
			axios
				.get(`${process.env.VUE_APP_rootUrl}/institutions/` + id)
				.then(({ data }) => {
					commit(INSTITUTION_DETAIL, data)
					resolve(data)
				})
				.catch(reject)
		})
	},
	getMyInstitution({ commit }) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_DETAIL_REQUEST)
			axios
				.get(`/institution`)
				.then(({ data }) => {
					commit(INSTITUTION_DETAIL, data)
					resolve(data)
				})
				.catch(reject)
		})
	},
	listMyInstitutions({ commit }) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_DETAIL_REQUEST)
			axios
				.get(`/institution/list`)
				.then(({ data }) => {
					commit(INSTITUTION_LIST_DETAIL, data)
					resolve(data)
				})
				.catch(reject)
		})
	},
	attachInstitution({ commit, dispatch }, institution_id) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_DETAIL_REQUEST)
			axios
				.post('/institution', { institution_id: institution_id })
				.then(({ data }) => {
					dispatch('listMyInstitutions')
					commit(INSTITUTION_DETAIL, data.institution)
					commit(MERCHANT_DETAIL, data)
					dispatch('updateBCProduct', {
						merchant_id: data.id,
						institution_id
					})
					resolve(data.institution)
				})
				.catch(err => reject(err))
		})
	},
	updateBCProduct({}, { merchant_id, institution_id }) {
		return new Promise((resolve, reject) => {
			axios
				.put('/bigcommerce/update-product', {
					merchant_id,
					institution_id
				})
				.then(() => resolve())
				.catch(err => reject(err))
		})
	},
	removeInstitution({ dispatch }, inst_id) {
		return new Promise((resolve, reject) => {
			axios
				.delete(`/institution/${inst_id}`)
				.then(() => {
					dispatch('listMyInstitutions')
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[INSTITUTION_DETAIL_REQUEST](state) {
		state.isFetching = true
	},
	[INSTITUTION_DETAIL](state, institution) {
		state.isFetching = false
		state.item = institution
	},
	[INSTITUTION_LIST_DETAIL](state, list) {
		state.isFetching = false
		state.list = list
	}
}

export default {
	state,
	actions,
	getters,
	mutations
}
