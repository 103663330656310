<template>
	<div
		class="dashboard"
		v-if="hasUser"
		v-bind:class="{
			hasDonationSourceWarning:
				merchant.institution && !merchant.payment_last_4
		}"
	>
		<sidebar />
		<main class="content" id="content">
			<div
				class="donationSource-banner"
				v-if="merchant.institution && !merchant.stripe_source"
			>
				<strong>No Donation Source</strong> - You must add one before
				the end of the month to continue donating RoundUps to your
				cause.
				<router-link :to="{ name: 'select-bank' }">Add now</router-link>
			</div>

			<div class="donationSource-banner" v-if="!merchant.institution">
				<strong>No active cause! </strong>You must activate a selected
				nonprofit in order to enable the RoundUp at Checkout embed.
				<router-link :to="{ name: 'select-cause' }"
					>Add now</router-link
				>
			</div>
			<router-view v-if="hasUser"></router-view>
			<modal v-model="noDevData">
				<h3>
					We have no test data for this page. Would you like to view
					live data?
				</h3>
				<div class="roundup-row">
					<ui-button @click="dismissModal" class="secondary"
						>Dismiss</ui-button
					>
					<ui-button @click="showLive">Show Live Data</ui-button>
				</div>
			</modal>
		</main>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Sidebar from './Sidebar.vue'
import Modal from '../ui/Modal'
import UiButton from '../ui/Button'
import UiLabel from '../ui/Label'

export default {
	name: 'layout',
	components: {
		Sidebar,
		Modal,
		UiButton,
		UiLabel
	},
	data() {
		return {
			showModal: true
		}
	},
	computed: {
		...mapGetters(['hasUser', 'hasStripe', 'institutionUuid', 'hasBrand']),
		...mapState({
			merchant: ({ merchant }) => merchant.item
		}),
		noDevData() {
			return (
				window.localStorage.getItem('dev') === 'true' &&
				window.location.href.includes('/Donations') &&
				this.showModal
			)
		}
	},
	methods: {
		...mapActions(['toggleDev']),
		showLive() {
			this.toggleDev()
		},
		dismissModal() {
			this.showModal = false
		},
		onPage(page) {
			return !window.location.href.includes(page)
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.dashboard {
	width: 100%;
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	.content {
		width: 100%;
		flex-grow: 1;
	}
	.banner {
		width: 100%;
		background: #184fd5;
		max-width: 1280px;
		padding: 8px 0px;
		margin: 10px 0px;
		border-radius: 14px;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		p {
			font-size: 20px;
			margin: 0;
			font-weight: 600;
			color: #fff;
		}
	}
	.red {
		background: #e25061;
	}
}
.donationSource-banner {
	background: $roundup-red;
	color: $white;
	margin-bottom: 20px;
	text-align: center;
	border-radius: 10px;
	height: 64px;
	font-size: 20px;
	line-height: 20px;
	margin-top: 10px;
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	a {
		color: $white;
	}
}
</style>
