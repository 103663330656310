/* eslint no-multi-spaces: ["error", { exceptions: { "VariableDeclarator": true } }] */

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_HEADERS = 'SET_HEADERS'

export const USER_SET_CURRENT = 'USER_SET_CURRENT'
export const USER_REMOVE_CURRENT = 'USER_REMOVE_CURRENT'
export const USER_ACCOUNTS = 'USER_ACCOUNTS'
export const USER_SUCCESS_STEP = 'USER_SUCCESS_STEP'
export const USER_SET_INSTITUTION = 'USER_SET_INSTITUTION'

export const DONOR_LIST = 'DONOR_LIST'
export const DONOR_LIST_REQUEST = 'DONOR_LIST_REQUEST'
export const DONOR_DETAIL = 'DONOR_DETAIL'

export const SEARCH_LIST = 'SEARCH_LIST'
export const INSTITUTION_LIST = 'INSTITUTION_LIST'
export const INSTITUTION_LIST_REQUEST = 'INSTITUTION_LIST_REQUEST'
export const INSTITUTION_SEARCH_LIST_REQUEST = 'INSTITUTION_SEARCH_LIST_REQUEST'
export const INSTITUTION_DETAIL = 'INSTITUTION_DETAIL'
export const INSTITUTION_LIST_DETAIL = 'INSTITUTION_LIST'
export const INSTITUTION_DETAIL_REQUEST = 'INSTITUTION_DETAIL_REQUEST'
export const INSTITUTION_SEARCH = 'INSTITUTION_SEARCH'

export const MERCHANT_DETAIL_REQUEST = 'MERCHANT_DETAIL_REQUEST'
export const MERCHANT_DETAIL = 'MERCHANT_DETAIL'
export const MERCHANT_HOME = 'MERCHANT_HOME'
export const MERCHANT_EMBED = 'MERCHANT_EMBED'

export const MERCHANT_LIST_REQUEST = 'MERCHANT_LIST_REQUEST'
export const MERCHANT_LIST_COMPLETE = 'MERCHANT_LIST_COMPLETE'

export const REPORT_LIST = 'REPORT_LIST'
export const REPORT_LIST_REQUEST = 'REPORT_LIST_REQUEST'
export const REPORT_TYPE_SET = 'REPORT_TYPE_SET'
export const REPORT_GENERAL = 'REPORT_GENERAL'
export const TOGGLE_DEV_ENV = 'TOGGLE_DEV_ENV'
export const SET_DEV_ENV = 'SET_DEV_ENV'

export const USER_LIST = 'USER_LIST'
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_DETAIL = 'USER_DETAIL'

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST'
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT'
export const UPDATE_PROJCET = 'UPDATE_PROJCET'
export const PROJECT_DETAIL = 'PROJECT_DETAIL'

export const GET_TEMPLATES = 'GET_TEMPLATES'
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATE_SUCCESS'

export const GET_MARKETING_LIBRARY = 'GET_MARKETING_LIBRARY'
export const GET_MARKETING_LIBRARY_SUCCESS = 'GET_MARKETING_LIBRARY_SUCCESS'

// Search Algolia
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY'
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS'

// Algolia Search Tracking

export const EVENTS_LIST_SEND = 'EVENTS_LIST_SEND'
export const EVENTS_LIST_SET = 'EVENTS_LIST_SET'
export const SEND_SET = 'SEND_SET'
export const QUERYID_SET = 'QUERYID_SET'
export const EVENTS_LIST_RESET = 'EVENTS_LIST_RESET'

export const EMBED_DETAIL = 'EMBED_DETAIL'
export const EMBED_DETAIL_SET_ACTIVE_ORG_UUID =
	'EMBED_DETAIL_SET_ACTIVE_ORG_UUID'
