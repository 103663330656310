import Vue from 'vue'
import Vuex from 'vuex'

import me from './modules/me'
import donorDetail from './modules/donor.detail'
import donorList from './modules/donor.list'
import merchant from './modules/merchant'
import institutionList from './modules/institution.list'
import institutionDetail from './modules/institution.detail'
import reportList from './modules/report.list'
import userDetail from './modules/user.detail'
import userList from './modules/user.list'
import projectList from './modules/project'
import projectDetail from './modules/project.detail'
import notify from './modules/notify'
import marketing from './modules/marketing'
import merchantList from './modules/merchant.list'
import events from './modules/events'
import searchList from './modules/search.list'
import embedDetail from './modules/embed.detail'
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		me,
		donorDetail,
		donorList,
		merchant,
		institutionList,
		institutionDetail,
		reportList,
		userDetail,
		userList,
		projectList,
		projectDetail,
		notify,
		marketing,
		merchantList,
		events,
		searchList,
		embedDetail
	},
	strict: true
})
