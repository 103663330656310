<template>
	<section class="sidebar">
		<nav style="position: fixed; top: -20; left: -20">
			<profile-dropdown :institution="merchant" />

			<li v-if="isOnboarding">
				<div class="getStarted-wrapper">
					<router-link
						class="getStarted-header main-menu-item"
						:to="{ name: 'steps' }"
					>
						Getting Started
					</router-link>
					<div class="getStarted-menu">
						<router-link :to="{ name: 'select-cause' }">
							<i
								class="fa fa-circle"
								v-if="!merchant.institution"
							></i>
							<i
								class="fas fa-check-circle"
								v-if="merchant.institution"
							></i>
							<span>Cause</span>
						</router-link>
						<router-link
							:to="{ name: 'select-bank' }"
							:class="{ disabled: !merchant.institution }"
						>
							<i
								class="fa fa-circle"
								v-if="
									!(
										merchant.payment_verified &&
										merchant.payment_last_4
									)
								"
							></i>
							<i
								class="fas fa-check-circle"
								v-if="
									merchant.payment_verified &&
										merchant.payment_last_4
								"
							></i>
							<span>Donation Source</span>
						</router-link>
					</div>
				</div>
			</li>
			<li>
				<router-link :to="{ name: 'dashboard' }">
					<i class="fas fa-home"></i>
					<span class="main-menu-item">Home</span>
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'reports' }">
					<i class="fas fa-chart-bar"></i>
					<span class="main-menu-item">General Reports</span>
				</router-link>
				<ul>
					<li>
						<router-link :to="{ name: 'reports.donations' }">
							<span>Donations</span>
						</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'reports.transactions' }">
							<span style="margin-top: 0px">RoundUps</span>
						</router-link>
					</li>
				</ul>
			</li>
			<li>
				<!-- TODO: put back v-if="!isOnboarding" -->
				<router-link :to="{ name: 'select-cause' }">
					<i class="fas fa-flag"></i>
					<span class="main-menu-item">Your Cause</span>
				</router-link>
				<ul>
					<li>
						<router-link :to="{ name: 'search' }">
							<span style="margin-top: 0px">Nonprofits</span>
						</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'donation-form' }">
							<span style="margin-top: 0px">Donation Form</span>
						</router-link>
					</li>
				</ul>
			</li>

			<li>
				<router-link :to="{ name: 'api' }">
					<i class="fas fa-code"></i>
					<span class="main-menu-item">API Keys</span>
				</router-link>
				<ul>
					<router-link :to="{ name: 'demo' }">
						<span>Checkout Demo</span>
					</router-link>
				</ul>
			</li>
			<div style="position: fixed; left: 25; bottom: 60px">
				<a
					v-bind:style="{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginLeft: '5px'
					}"
				>
					<RoundupToggle
						v-bind:style="{ flex: 1 }"
						@change="devCheck"
						:checked="dev"
					>
					</RoundupToggle>
					<p
						v-bind:style="{
							flex: 4,
							marginLeft: '5px',
							fontSize: '14px',
							marginBottom: '0px',
							fontWeight: 600,
							color: '#71788c'
						}"
					>
						Test Data
					</p>
				</a>
				<li>
					<router-link :to="{ name: 'profile' }">
						<i class="fas fa-cog"></i>
						<span class="main-menu-item">Settings</span>
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'logout' }">
						<i class="fas fa-sign-out-alt"></i>
						<span class="main-menu-item">Logout</span>
					</router-link>
				</li>
			</div>
		</nav>
		<div class="roundup-logo"></div>
	</section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ProfileDropdown from './ProfileDropdown'
import UiLabel from '../ui/Label'
import UiToggle from '../ui/Toggle'
import UiButton from '../ui/Button'

export default {
	name: 'sidebar',
	components: { ProfileDropdown, UiLabel, UiToggle, UiButton },
	data() {
		return {
			publicKey: process.env.VUE_APP_plaidPublicKey,
			env: process.env.VUE_APP_plaidEnv,
			webhook: process.env.VUE_APP_plaidWebhook,
			clientName: 'RoundUp App',
			countryCodes: ['US']
		}
	},
	computed: {
		...mapState({
			current: ({ me }) => me.current,
			showMenu: ({ params }) => params.showMenu,
			merchant: ({ merchant }) => merchant.item
		}),
		...mapGetters(['merchant', 'onboardingStep', 'dev']),
		onRoundups() {
			return window && window.location.href.includes('Transactions')
		},
		isOnboarding() {
			if (!this.merchant) {
				return false
			}
			if (!this.merchant.institution) {
				return true
			}
			if (!this.merchant.stripe_source) {
				return true
			}
			return false
		}
	},
	methods: {
		...mapActions([
			'getMerchantDetails',
			'toggleDev',
			'getPublicToken',
			'refreshLink'
		]),
		devCheck() {
			setTimeout(() => {
				this.toggleDev()
			}, 300)
		},
		nextUp() {
			return 'Step ' + (this.onboardingStep + 1)
		},
		viewMarketingMaterials() {
			window.analytics.track('view_marketing_materials')
			window.open('https://roundupapp.com/marketing', '_blank')
		},
		viewTexting() {
			window.analytics.track('view_texting')
			window.open('https://app.texting.roundupapp.com', '_blank')
		},
		verifyLink() {
			this.getPublicToken()
				.then(data => {
					const handler = window.Plaid.create({
						clientName: this.clientName,
						env: this.env,
						key: this.publicKey,
						onSuccess: res => {
							this.refreshLink({ token: res }).then(() => {
								this.$swal({
									title: 'Success',
									text:
										"You're all setup! Thanks for helping us turn pocket change into global change!",
									showCancelButton: false,
									allowOutsideClick: false,
									allowEscapeKey: false
								})
							})
						},
						onError: err => {
							console.log(err)
						},
						product: 'auth',
						token: data.public_token,
						webhook: this.webhook
					})
					handler.open()
				})
				.catch(err => {
					console.log(err)
					this.$swal(
						'Error',
						'It looks like we are unable to relink your bank at this time. Please contact support.'
					)
				})
		},
		route(name) {
			this.$router.push({ name: name })
		}
	},
	mounted() {
		this.getMerchantDetails()
		if (!window.Plaid) {
			let plaidScript = document.createElement('script')
			plaidScript.setAttribute(
				'src',
				'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
			)
			document.head.appendChild(plaidScript)
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.sidebar {
	width: 300px;
	min-height: 100vh;
	padding: 20px;
	z-index: 99;
	background: $roundup-bone;
	margin-right: 20px;
	position: relative;
	max-width: 250px;
	.main-menu-item {
		font-weight: 600 !important;
	}
	nav {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		li {
			list-style: none;
			a {
				&.getStarted-header {
					color: $black;
				}
			}
			.getStarted-menu {
				a {
					font-weight: 400;
				}
			}
			ul {
				padding: revert;
				margin: initial;
				margin-top: 0px;
				margin-bottom: 0px;
				margin-left: 5px;
				a {
					font-weight: 400;
				}
			}
		}
		a {
			color: $roundup-medium-grey;
			font-size: 14px;
			font-weight: bold;
			text-decoration: none;
			display: flex;
			align-items: center;
			padding: 5px 10px 5px 0px;
			border-radius: 8px;
			span {
				display: inline-block;
			}
			.icon {
				font-size: 42px;
				vertical-align: middle;
				height: 42px;
				margin-top: -7px;
				margin-left: -8px;
			}
			.far {
				margin: 10px 10px 10px 5px;
				font-size: 18px;
			}
		}
	}
	.onboarding-progress {
		background: $roundup-light-blue-opaque;
		border-radius: 5px;
		width: 125%;
		border-top: solid 2px $roundup-light-grey;
		border-bottom: solid 2px $roundup-light-grey;
		padding: 5px;
		h4 {
			color: $roundup-primary;
			margin: 0px;
			margin-bottom: 5px;
		}
		.shell {
			height: 28px;
			width: 100%;
			border: 1px solid $roundup-primary;
			border-radius: 13px;
			background: #fff;
			padding: 3px;
		}
		.bar {
			background: linear-gradient(
				-90deg,
				$roundup-gradient1,
				$roundup-gradient2,
				$roundup-gradient3
			);
			height: 20px;
			width: 15px;
			border-radius: 9px;
			span {
				float: right;
				padding: 4px 5px;
				color: $roundup-primary;
				font-size: 0.7em;
			}
		}
	}
	.roundup-logo {
		position: fixed;
		bottom: 0px;
		background: url('../../assets/roundup_logo.svg');
		background-size: 137px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 137px;
		height: 14px;
		margin: 0px 0px 20px;
		opacity: 0.2;
	}
}
.sidebar nav a.router-link-active,
.sidebar nav a:focus,
.sidebar nav a:hover {
	color: $roundup-primary;
	cursor: pointer;
	background: inherit !important;
}
.sidebar nav a .fas,
.sidebar nav a .fa {
	margin: 10px 10px 10px 5px;
	font-size: 18px;
}
.blue {
	.logo {
		background: url('../../assets/icon_blue.png');
		background-size: 20px 25px;
		background-repeat: no-repeat;
		background-position: center;
		margin-top: 20px;
		margin-right: 10px;
		height: 25px;
		width: 20px;
		margin: 0px 0px 20px;
	}
}
.roundup-logo {
	background: url('../../assets/icon.png');
	background-size: 20px 25px;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 20px;
	margin-right: 10px;
	height: 25px;
	width: 20px;
	margin: 0px 0px 20px;
}
.getStarted-wrapper {
	background: $roundup-light-blue-opaque;
	border-radius: 5px;
	padding: 20px;
}
.verifyDeposits-link {
	padding: 0 !important;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
}
</style>
