import axios from '../../network/axios'
// import { get } from '../../utilities'
import { MERCHANT_LIST_REQUEST, MERCHANT_LIST_COMPLETE } from '../types'

const state = {
	isFetching: false,
	list: []
}

const getters = {
	merchantList: state => state.list
}

// actions
const actions = {
	listMerchants({ commit }, q) {
		return new Promise((resolve, reject) => {
			commit(MERCHANT_LIST_REQUEST)
			axios
				.get('/list?query=' + q)
				.then(({ data }) => {
					commit(MERCHANT_LIST_COMPLETE, data)
					resolve()
				})
				.catch(err => reject(err))
		})
	},
	switchMerchant({ commit, dispatch }, id) {
		return new Promise((resolve, reject) => {
			commit(MERCHANT_LIST_REQUEST)
			axios
				.post('/switchMerchant', { id })
				.then(() => {
					dispatch('getCurrentUser')
					resolve()
				})
				.catch(err => reject(err))
		})
	}
}

const mutations = {
	[MERCHANT_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[MERCHANT_LIST_COMPLETE](state, list) {
		state.isFetching = false
		state.list = list.results
		state.total = list.total
	}
}

export default {
	state,
	actions,
	getters,
	mutations
}
