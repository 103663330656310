import axios from '@/network/axios'
import { get } from '@/utilities'
import { DONOR_DETAIL } from '../types.js'

const state = {
	isFetching: false,
	item: null,
	graph: null
}

// actions
const actions = {
	getDonor({ commit }, id) {
		let params = ''
		if (window.location.href.split('?').length > 1) {
			params = '?' + window.location.href.split('?')[1]
		}
		return new Promise((resolve, reject) => {
			axios
				.get('/donors/' + id + params)
				.then(({ data }) => {
					commit(DONOR_DETAIL, data)
					resolve()
				})
				.catch(reject)
		})
	}
}

const mutations = {
	[DONOR_DETAIL](state, response) {
		state.isFetching = false
		state.item = response.result
		state.graph = get(response, 'graph', [])
	}
}

export default {
	state,
	actions,
	mutations
}
