const numeral = require('numeral')

export const get = function(obj, key, empty) {
	return (
		key.split('.').reduce(function(o, x) {
			return typeof o === 'undefined' || o === null ? o : o[x]
		}, obj) || (isEmpty(empty) ? null : empty)
	)
}

export const set = function(obj, key, value) {
	if (!isEmpty(value)) {
		obj[key] = value
	}
}

export const isEmpty = function(val) {
	return typeof val === 'undefined' || val == null || val.length <= 0
}

export const has = function(obj, key) {
	return key.split('.').every(function(x) {
		if (typeof obj !== 'object' || obj === null || !(x in obj)) {
			return false
		}
		obj = obj[x]
		return true
	})
}

export const contains = function(objs, eqlFunc) {
	for (let value of objs) {
		if (eqlFunc(value)) {
			return true
		}
	}
	return false
}

export const indexOf = function(objs, func) {
	for (let i in objs) {
		if (func(objs[i])) {
			return i
		}
	}
	return null
}

export const split = function(str, char, func) {
	if (isEmpty(str)) return []
	func = func || (val => val)
	let objs = str.split(char)
	for (let i in objs) {
		objs[i] = func(objs[i])
	}
	return objs
}

export const pluck = function(obj, keys) {
	if (keys == null) return {}
	return keys.reduce(function(newObj, key) {
		newObj[key] = obj[key]
		return newObj
	}, {})
}

export const prune = function(obj, func) {
	func = func || isEmpty
	for (const key in obj) {
		if (func(obj[key])) {
			delete obj[key]
		}
	}
	return obj
}

export const first = function(array) {
	return !isEmpty(array) ? (array.length > 0 ? array[0] : null) : null
}

export const last = function(array) {
	return array[array.length - 1]
}

export const comb = function() {
	return [...arguments].join('')
}

export const numberFormat = function(value) {
	if (!value) return 'None'
	return numeral(value).format('$0,0.00')
}

export const dollarInputFormat = function(value) {
	if (!value) return null
	value = value.toString().replace(/[^0-9]/g, '')
	return numeral(value).format('$0,0')
}

export const commaFormat = function(value) {
	if (!value) return 'None'
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const loadScript = function(src) {
	return new Promise(function(resolve, reject) {
		if (document.querySelector('script[src="' + src + '"]')) {
			resolve()
			return
		}
		const el = document.createElement('script')

		el.type = 'text/javascript'
		el.async = true
		el.src = src

		el.addEventListener('load', resolve)
		el.addEventListener('error', reject)
		el.addEventListener('abort', reject)

		document.head.appendChild(el)
	})
}

export const incompleteStep = function(user) {
	if (!user.has_valid_card) {
		return 'No Attached Card'
	} else if (!user.has_accounts) {
		return 'No Bank Account'
	} else if (!user.has_pulled_transactions) {
		return 'No Transactions'
	}
	return 'Incomplete'
}

export const simpleDateFormat = function(date) {
	var monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
	const day = date.getDate()
	const monthIndex = date.getMonth()
	const year = date.getFullYear()

	return monthNames[monthIndex] + ' ' + day + ', ' + year
}

export const getLastMonth = function() {
	const date = new Date()
	var monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
	var year = date.getFullYear()
	var monthIndex = date.getMonth() - 1
	if (monthIndex < 0) {
		year = year - 1
		monthIndex = 11
	}
	return monthNames[monthIndex] + ' ' + year
}

export const isMobileScreenSize = function() {
	return document.documentElement.clientWidth <= 480
}

export const debounce = function(func, wait, immediate) {
	var timeout, args, context, timestamp, result
	if (wait == null) wait = 100

	function later() {
		var last = Date.now() - timestamp

		if (last < wait && last >= 0) {
			timeout = setTimeout(later, wait - last)
		} else {
			timeout = null
			if (!immediate) {
				result = func.apply(context, args)
				context = args = null
			}
		}
	}

	var debounced = function() {
		context = this
		args = arguments
		timestamp = Date.now()
		var callNow = immediate && !timeout
		if (!timeout) timeout = setTimeout(later, wait)
		if (callNow) {
			result = func.apply(context, args)
			context = args = null
		}
		return result
	}

	debounced.clear = function() {
		if (timeout) {
			clearTimeout(timeout)
			timeout = null
		}
	}

	debounced.flush = function() {
		if (timeout) {
			result = func.apply(context, args)
			context = args = null

			clearTimeout(timeout)
			timeout = null
		}
	}

	return debounced
}

export const enums = {
	templates: {
		smsReminder: '1',
		emailReminder: '2',
		smsThank: '3'
	}
}

export const getUrlParams = function(url) {
	var queryString = url ? url.split('?')[1] : window.location.search.slice(1)
	var obj = {}

	if (queryString) {
		queryString = queryString.split('#')[0]
		var arr = queryString.split('&')

		for (var i = 0; i < arr.length; i++) {
			var a = arr[i].split('=')
			var paramNum
			var paramName = a[0].replace(/\[\d*\]/, function(v) {
				paramNum = v.slice(1, -1)
				return ''
			})
			var paramValue = typeof a[1] === 'undefined' ? true : a[1]

			paramName = paramName.toLowerCase()
			paramValue = paramValue.toLowerCase()

			if (obj[paramName]) {
				if (typeof obj[paramName] === 'string') {
					obj[paramName] = [obj[paramName]]
				}
				if (typeof paramNum === 'undefined') {
					obj[paramName].push(paramValue)
				} else {
					obj[paramName][paramNum] = paramValue
				}
			} else {
				obj[paramName] = paramValue
			}
		}
	}
	return obj
}
