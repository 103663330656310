<template>
	<label class="roundup-label" @click="$emit('click')">
		<slot></slot>
	</label>
</template>

<script>
export default {
	name: 'roundup-label'
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.roundup-label {
	display: block;
	position: relative;
	padding-bottom: 18px;
}
</style>
