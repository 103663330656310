import axios from '../../network/axios'
import { SEARCH_LIST, INSTITUTION_SEARCH_LIST_REQUEST } from '../types.js'

const state = {
	isFetching: false,
	items: [],
	total: 0
}

const getters = {
	searchList: state => state.items,
	listLoading: state => state.isFetching
}

// actions
const actions = {
	getInstitutions({ commit }) {
		return new Promise(resolve => {
			commit(INSTITUTION_SEARCH_LIST_REQUEST)
			axios
				.get(`${process.env.VUE_APP_rootUrl}/institutions/random`)
				.then(({ data }) => {
					commit(SEARCH_LIST, data)
					resolve()
				})
		})
	},
	searchInstitutions({ commit }, params) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_SEARCH_LIST_REQUEST)
			axios
				.get(
					`${
						process.env.VUE_APP_rootUrl
					}/institutions/search?query=${encodeURI(params)}`
				)
				.then(({ data }) => {
					if (data.found === 0) {
						commit(SEARCH_LIST, [
							{
								id: -1,
								name: 'No results found'
							}
						])
						resolve()
						return
					}
					const res = data.hits.map(hit => hit.document) || []
					commit(SEARCH_LIST, res)
					resolve()
				})
				.catch(err => console.log(err.message))
		})
	},
	searchInstitutionByEIN({ commit }, ein) {
		return new Promise((resolve, reject) => {
			commit(INSTITUTION_SEARCH_LIST_REQUEST)
			axios
				.get(`${process.env.VUE_APP_rootUrl}/institutions/` + ein)
				.then(({ data }) => {
					commit(SEARCH_LIST, data.results)
					resolve()
				})
				.catch(reject)
		})
	},
	clearSearch({ commit }) {
		commit(SEARCH_LIST, [])
	}
}

const mutations = {
	[INSTITUTION_SEARCH_LIST_REQUEST](state) {
		state.isFetching = true
	},
	[SEARCH_LIST](state, response) {
		state.isFetching = false
		state.items = response
		state.total = response.length
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
